import React from 'react';
import { graphql, Link } from 'gatsby';

import { Layout, TitleAndMeta, Single } from '../components';

export default () => {
  return (
    <Layout>
      <TitleAndMeta title="Page not found" />
      <Single>
        <h2>Page Not Found</h2>
        <p>Page you are looking for does not exist</p>
      </Single>
    </Layout>
  );
};
